.Contact {
  font-family: sans-serif;
  // text-align: center;

  .ant-form-item {
    width: auto;
  }
  .ant-input:placeholder-shown {
    text-align: left;
  }
  .ant-select-single.ant-select-customize-input
    .ant-select-selector
    .ant-select-selection-placeholder {
    text-align: left;
  }
}
h1,
h2,
h3 {
  color: #130f40;
  width: 100%;
  margin-top: 0.5em;
}
p {
  width: 100%;
  color: #130f40;
  margin: 2em auto;
}
