// Sizes control
$grey: grey;
$white: white;
$darkGrey: darken($grey, 10%);
$darkerGrey: darken($grey, 30%);
$lightGrey: lighten($grey, 30%);
$lighterGrey: lighten($grey, 45%);

$lightText: lighten($grey, 20%);

$xsBreak: 460px;
$smBreak: 576px;
$mdBreak: 768px;
$lgBreak: 992px;
$xlBreak: 1200px;
$xxlBreak: 1600px;

// Font sizes
$font12: 12px;
$font14: 14px;
$font16: 16px; // Base
$font18: 18px;
$font20: 20px;
$font24: 24px;
$font30: 30px;
$font36: 36px;
$font48: 48px;
$font60: 60px;
$font72: 72px;
// Spaces
$space4: 4px;
$space8: 8px;
$space12: 12px;
$space16: 16px;
$space24: 24px;
$space32: 32px;
$space48: 48px;
$space64: 64px;
$space96: 96px;
$space128: 128px;
$space192: 192px;
$space256: 256px;
$space384: 384px;
$space512: 512px;
$space640: 640px;
$space768: 768px;

// Main colors
$white: hsl(0, 0%, 100%);
$primaryColor: hsl(227, 92, 47);
$secondaryColor: hsl(193, 100, 64);


// Colors
// $primaryColor: #130f40;
$lightenPrimaryColor: #30336b;
$linkColor: #6ab04c;
$linkActiveColor: #eb4d4b;


// gray
$gray100: hsl(0, 0, 90);
$gray200: hsl(208, 21, 88);
$gray300: hsl(210, 16, 76);
// $gray400: hsl(0, 0, 161);
$gray500: hsl(208, 12, 58);
$gray600: hsl(207, 12, 43);
$gray700: hsl(209, 15, 28);
$gray800: hsl(240, 10, 16);
$gray900: hsl(240, 12, 13);

@mixin boxed {
  padding: $space16;
  margin: 0 auto;
}

@mixin boxedLg {
  @include boxed();

  max-width: $lgBreak;
}

@mixin boxedMd {
  @include boxed();

  max-width: $mdBreak;
}
