@import './variables';

.Home {
  position: relative;
  height: 100vh;
  width: 100vw;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  &-video {
    height: 100%;
    width: 100%;
    object-fit: cover;
    & video {
      height: 100% !important;
    }
    & .ytp-chrome-top .ytp-show-cards-title {
      display: none !important;
    }
  }
  &-content {
    @media screen and (max-width: $smBreak) {
      width: 100%;
      height: 100%;
      max-width: initial;
      max-height: initial;
      justify-content: center;
    }
    position: absolute;
    width: 50%;
    height: 50%;
    max-width: 450px;
    max-height: 350px;
    color: #f1f1f1;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    padding: 32px;
    &-title {
      color: white;
      text-transform: uppercase;
      font-size: 1.7em;
      text-align: center;
    }
    &-description {
      @media screen and (max-width: $smBreak) {
        display: block;
        flex: none;
        margin-bottom: 32px;
      }
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: center;
      font-size: 1.1em;
    }
  }
}
.video-background {
  background-color: #ffffff90;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: -99;
  &::after {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: #ffffff90;
  }
}
.video-foreground,
.video-background iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
@media (min-aspect-ratio: 16/9) {
  .video-foreground {
    height: 300%;
    top: -100%;
  }
}
@media (max-aspect-ratio: 16/9) {
  .video-foreground {
    width: 300%;
    left: -100%;
  }
}
