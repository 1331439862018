.TablePesos {
  margin: 0;
  padding: 0;

  .ant-table {
    background: transparent;
    .ant-table-thead > tr > th {
      background: transparent;
      color: #212121;
      text-align: center;
      text-transform: uppercase;
      border-bottom: 1px solid #212121;
    }
    .ant-table-tbody > tr > td {
      border: 0px solid transparent;
      text-align: center;
      // padding: 0.5em 0;
    }
    .ant-table-tbody > tr.ant-table-row:hover > td {
      background: #b1b3ff2b;
    }
    tr.ant-table-expanded-row > td,
    tr.ant-table-expanded-row:hover > td {
      background: #b1b3ff21;
    }
  }
  .ant-tag {
    font-family: monospace;
    // font-size: 1em;
    // padding: 0.5em;
  }
  .space-align-block .mock-block {
    display: inline-block;
    background: #b1b3ff21;
    align-items: baseline;
    justify-content: center;
    // margin: 1em auto;
  }
}
